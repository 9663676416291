import { Placement } from "@ori/analytics-data";

/**
 * Events taken from "Ori Kendo Extensions" /.../ ori.kendo.validator.js
 * and defined is only a subset of those which make sense to listen to for analytics purposes.
 */
export enum KendoValidatorAnalyticEventType {
    InputValidate = "inputValidate",
    InputRemoteValidate = "inputRemoteValidate",
    InvalidInputFocus = "invalidInputFocus",
}

export interface IKendoFormValidationAnalyticsOptions {
    /**
     * Kendo Validator events to listen to for analytics purposes.
     */
    bindToEvents: KendoValidatorAnalyticEventType[];

    /**
     * Placement for GTM processing purposes.
     *
     * Please note that null in not a valid value and will cause
     * runtime error and event not to be forwarded to data layer.
     */
    placementForEvent: Placement | null;
}
